.aboutWrapper {
    display: flex;
    flex-direction: column;
    min-height: 1000px;
    justify-content: center;
}

.aboutWrapper__info {
    display: flex;
    justify-content: space-between;
}

.aboutWrapper__image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.up {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
}
