.copyrightPage {
  margin-top: 300px;
  margin-bottom: 174px;
}

.copyrightPage__title {
  margin-bottom: 25px;
}

.characters__item {
  margin-bottom: 48px;
}

.characters__divider {
  height: 1px;
  background: #000;
}

.characters {
  margin-top: 152px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

@media screen and (max-width: 600px) {
  .copyrightPage {
    margin-top: 70px;
    margin-bottom: 50px;
  }

  .characters__item {
    margin-bottom: 15px;
  }

  .characters {
    margin-top: 50px;
    row-gap: 10px;
  }
}