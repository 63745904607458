:root {
    --font-family-main: 'NotoSans', sans-serif;
    --font-family-monsterrat: 'Monsterrat', sans-serif;

    --font-size-m: 18px;
    --font-line-m: 18px;
    --font-m: var(--font-size-m) / var(--font-line-m) var(--font-family-main);

    --font-m-monsterrat: var(--font-size-m) / var(--font-line-m) var(--font-family-monsterrat);

    --font-size-l: 24px;
    --font-line-l: 110%;
    --font-l: var(--font-size-l) / var(--font-line-l) var(--font-family-main);

    --font-size-xl: 68px;
    --font-line-xl: 110%;
    --font-xl: var(--font-size-xl) / var(--font-line-xl) var(--font-family-main);

    --overlay-color: rgba(0 0 0 / 60%);

    --primary-color: #858585;
    --secondary-color: #00A3FF;

    --background-color: #F9F9F9;

}
