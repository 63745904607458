.header {
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
    background-color: #FFFFFF;
    z-index: 100;
    /*background-color: hsla(0, 0%, 100%, .8);*/
    /*backdrop-filter: blur(10px);*/
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 108px;
}

.header__menu {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
}

.header__logo {
    cursor: pointer;
    //background-color: var(--secondary-color);
    padding: 5px 9px;
}

.header__menu li a {
    margin-right: 3rem;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.40);
    font: var(--font-m);
    font-style: normal;
    font-weight: 500;
}

.header__menu li a:hover {
    color: var(--secondary-color);
}

.logoContainer {
    display: flex;
    align-items: center;
}
