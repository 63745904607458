.mainSection {
    margin-top: 24px;
    background-color: var(--background-color);
}

.main-menu__item a:hover {
    color: #FF5C00;
}

.mainWrapper {
    display: flex;
    align-items: center;
    min-height: 848px;
}

.mainWrapper__info {
    width: 916px;
    margin-right: 124px;
}

/*@media screen and (max-width: 1200px) {*/
/*    .mainWrapper__info {*/
/*        margin-right: 0px;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 500px) {*/
/*    .mainWrapper {*/
/*        display: flex;*/
/*        flex-direction: column;*/
/*        align-items: center;*/
/*    }*/
/*    .mainWrapper__info {*/
/*        margin-right: 0px;*/
/*        width: 100%;*/
/*    }*/

/*    .mainSection {*/
/*        margin-top: 100px;*/
/*    }*/
/*}*/



