.Paragraph {
  white-space: pre-wrap;
  text-transform: capitalize;
  color: var(--primary-color);
  font: var(--font-l);
  font-style: normal;
  font-weight: 400;
}

.Paragraph__span {
  color: rgba(255, 255, 255, 0.50);
  font-family: Quantico, sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.Paragraph__list {
  position: relative;
  list-style-type: none;
  padding-left: 20px;
  margin: 0;

  & li {
    margin-left: 15px;

    &::before {
      content: "-"; /* Добавляем белую черточку перед каждым элементом */
      color: black;
      position: absolute;
      left: 0px;
    }
  }

}

.Paragraph__list_dotted {
  position: relative;
  list-style-type: none;
  padding-left: 20px;
  margin: 0;

  & li {
    margin-left: 15px;

    &::before {
      content: "•";
      color: black;
      position: absolute;
      left: 0px;
    }
  }
}



.left {
  text-align: left;
}

.right {
  text-align: right;
}

.neutral {
  color: var(--neutral-color);
}

@media screen and (max-width: 600px) {
  .Paragraph {
    font: var(--font-m);
  }
}