.CharacterBlock {
  display: flex;
  column-gap: 60px;
  //justify-content: center;
}

.CharacterBlock__img img {
  width: 354px;
  height: 354px;
}

.CharacterBlock__info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.CharacterBlock__name {
  margin-bottom: 44px;
}

.CharacterBlock__title {
  font-weight: 400;
  line-height: 120%;
}

.CharacterBlock__icon img {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 600px){
  .CharacterBlock {
    flex-direction: column;
    //column-gap: 60px;
  }

  .CharacterBlock__img img {
    width: 150px;
    height: 150px;
  }

  .CharacterBlock__icon img {
    width: 50px;
    height: 50px;
  }

  .CharacterBlock__info {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .CharacterBlock__name {
    margin-bottom: 0;
  }
}