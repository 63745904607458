.contactWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 550px;
}

.pStrong {
    color: #000;

    font-family: NotoSans, sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.pWeak {
    color: #B1B1B1;

    font-family: NotoSans, "sans-serif";
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
}


.contactWrapper__image {
    position: relative;
}


